export default {
    title: 'Etape 3 : Télécharger les résultats',
    back_link: {
        route: 'Configurator_Step1',
        text: 'Retour au menu principal',
    },
    params: {
        title: 'Rappel des paramètres',
    },
    exports: {
        title: 'Téléchargements',
        types: [
            {
                type: 'xlsx',
                label: 'Tableur Excel',
                img: 'excel.png',
            },
            {
                type: 'xml',
                label: 'Fichier XML',
                img: 'xml.png',
            },
            {
                type: 'pdf',
                label: 'FDES (PDF)',
                img: 'pdf.png',
            },
        ],
        comment: 'Vous pouvez également retrouver vos résultats dans le menu "Mes FDES".'
    },
};
